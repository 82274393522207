import React from "react"
import styled from "styled-components"
const Title = () => {
  return (
    <Text>
      <div className="container">
        <h1 className="glitch" data-text="Front End Developer">
          Front End Developer
        </h1>
        <h1 className="glow">Front End Developer</h1>
      </div>
    </Text>
  )
}

export default Title

const Text = styled.div`
  user-select: none;
  cursor: default;
  position: relative;
  margin-top: 20px;
  text-transform: uppercase;
  justify-content: center;
  display: flex;
  font-family: "Share Tech", sans-serif;
  font-size: 35px;
  white-space: nowrap;

  .container {
    position: relative;
  }
  .glitch,
  .glow {
    min-width: 300px;
    max-width: 400px;

    font-family: "Share Tech", sans-serif;
    color: #dfbfbf;
    animation: glitch 5s 3s infinite;
    margin: 0 auto;
    position: relative;
    top: 0;

    left: 0;
  }
  .glow {
    text-shadow: 0 0 10px #dfbfbf;
    color: transparent;
    position: absolute;
    top: 0;
    left: -2px;
  }

  .glitch:before,
  .glow:before {
    content: "Front End Developer";
    position: absolute;
    left: 0;
    text-shadow: -1px 0 magenta;
    background: black;
    top: 0;
    animation: noise-1 5s linear infinite alternate-reverse,
      glitch 4s 5.05s infinite;
  }

  .glitch:after,
  .glow:after {
    content: "Front End Developer";
    position: absolute;
    left: 2px;
    text-shadow: -1px 0 lightgreen;
    background: #020202;
    top: 0;
    animation: noise-2 4s linear infinite alternate-reverse,
      glitch 4s 5s infinite;
  }

  @keyframes glitch {
    1% {
      transform: rotateX(10deg) skewX(90deg);
    }
    2% {
      transform: rotateX(0deg) skewX(0deg);
    }
  }
  @keyframes noise-1 {
    3.3333333333% {
      clip-path: inset(51px 0 40px 0);
    }
    6.6666666667% {
      clip-path: inset(23px 0 43px 0);
    }
    10% {
      clip-path: inset(27px 0 6px 0);
    }
    13.3333333333% {
      clip-path: inset(57px 0 28px 0);
    }
    16.6666666667% {
      clip-path: inset(26px 0 53px 0);
    }
    20% {
      clip-path: inset(7px 0 80px 0);
    }
    23.3333333333% {
      clip-path: inset(16px 0 44px 0);
    }
    26.6666666667% {
      clip-path: inset(67px 0 20px 0);
    }
    30% {
      clip-path: inset(25px 0 5px 0);
    }
    33.3333333333% {
      clip-path: inset(94px 0 3px 0);
    }
    36.6666666667% {
      clip-path: inset(36px 0 37px 0);
    }
    40% {
      clip-path: inset(73px 0 23px 0);
    }
    43.3333333333% {
      clip-path: inset(21px 0 79px 0);
    }
    46.6666666667% {
      clip-path: inset(46px 0 44px 0);
    }
    50% {
      clip-path: inset(80px 0 19px 0);
    }
    53.3333333333% {
      clip-path: inset(16px 0 66px 0);
    }
    56.6666666667% {
      clip-path: inset(82px 0 1px 0);
    }
    60% {
      clip-path: inset(90px 0 9px 0);
    }
    63.3333333333% {
      clip-path: inset(62px 0 19px 0);
    }
    66.6666666667% {
      clip-path: inset(96px 0 2px 0);
    }
    70% {
      clip-path: inset(16px 0 74px 0);
    }
    73.3333333333% {
      clip-path: inset(23px 0 15px 0);
    }
    76.6666666667% {
      clip-path: inset(37px 0 53px 0);
    }
    80% {
      clip-path: inset(30px 0 34px 0);
    }
    83.3333333333% {
      clip-path: inset(87px 0 7px 0);
    }
    86.6666666667% {
      clip-path: inset(84px 0 15px 0);
    }
    90% {
      clip-path: inset(52px 0 3px 0);
    }
    93.3333333333% {
      clip-path: inset(66px 0 23px 0);
    }
    96.6666666667% {
      clip-path: inset(83px 0 2px 0);
    }
    100% {
      clip-path: inset(27px 0 14px 0);
    }
  }
  @keyframes noise-2 {
    0% {
      clip-path: inset(73px 0 6px 0);
    }
    3.3333333333% {
      clip-path: inset(44px 0 53px 0);
    }
    6.6666666667% {
      clip-path: inset(36px 0 26px 0);
    }
    10% {
      clip-path: inset(40px 0 1px 0);
    }
    13.3333333333% {
      clip-path: inset(9px 0 20px 0);
    }
    16.6666666667% {
      clip-path: inset(65px 0 32px 0);
    }
    20% {
      clip-path: inset(40px 0 50px 0);
    }
    23.3333333333% {
      clip-path: inset(37px 0 63px 0);
    }
    26.6666666667% {
      clip-path: inset(27px 0 34px 0);
    }
    30% {
      clip-path: inset(52px 0 12px 0);
    }
    33.3333333333% {
      clip-path: inset(45px 0 5px 0);
    }
    36.6666666667% {
      clip-path: inset(95px 0 6px 0);
    }
    40% {
      clip-path: inset(86px 0 7px 0);
    }
    43.3333333333% {
      clip-path: inset(100px 0 1px 0);
    }
    46.6666666667% {
      clip-path: inset(77px 0 8px 0);
    }
    50% {
      clip-path: inset(93px 0 4px 0);
    }
    53.3333333333% {
      clip-path: inset(2px 0 42px 0);
    }
    56.6666666667% {
      clip-path: inset(28px 0 53px 0);
    }
    60% {
      clip-path: inset(42px 0 25px 0);
    }
    63.3333333333% {
      clip-path: inset(46px 0 54px 0);
    }
    66.6666666667% {
      clip-path: inset(60px 0 3px 0);
    }
    70% {
      clip-path: inset(10px 0 76px 0);
    }
    73.3333333333% {
      clip-path: inset(21px 0 20px 0);
    }
    76.6666666667% {
      clip-path: inset(82px 0 12px 0);
    }
    80% {
      clip-path: inset(80px 0 10px 0);
    }
    83.3333333333% {
      clip-path: inset(35px 0 5px 0);
    }
    86.6666666667% {
      clip-path: inset(42px 0 48px 0);
    }
    90% {
      clip-path: inset(71px 0 16px 0);
    }
    93.3333333333% {
      clip-path: inset(5px 0 78px 0);
    }
    96.6666666667% {
      clip-path: inset(97px 0 1px 0);
    }
    100% {
      clip-path: inset(94px 0 5px 0);
    }
  }
`

import React from "react"
import styled from "styled-components"
import Button from "../Button"
import Anilink from "gatsby-plugin-transition-link"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import TextContainer from "../TextContainer"
import Title from "../Title"

const getImage = graphql`
  query artImage {
    artImage: file(relativePath: { eq: "ComponentImages/myCanvas.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 560) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

const Art = () => {
  const { artImage } = useStaticQuery(getImage)

  return (
    <Container className="card">
      <Title title="Art" subtitle="" />
      <ArtCenter>
        <TextContainer className="card-body">
          <h1>
            “The cure for boredom is curiosity. There is no cure for curiosity.”
          </h1>
          <p>
            I decided to teach myself generative art as a hobby to see what
            else I could do with it, but it has become so much more for me, and
            now I use it as a way to add beauty to the world. I only attempt to
            create states of mind, of heart, and my work reflects how simplicity
            can become so many different things.
          </p>
          <Anilink fade to="/art/">
            <Button>Art</Button>
          </Anilink>
        </TextContainer>
        <ArtImage>
          <ImgContainer className="cardImage">
            <Img fluid={artImage.childImageSharp.fluid} alt="Art" />
          </ImgContainer>
        </ArtImage>
      </ArtCenter>
    </Container>
  )
}

export default Art

export const Container = styled.section`
  padding: 4rem 0;
`
export const ArtCenter = styled.div`
  width: 80vw;
  margin: 0 auto;
  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 3rem;
    align-items: center;
    margin-top: 3rem;
  }
  @media screen and (min-width: 1200px) {
    width: 95vw;
    max-width: 1170px;
  }
`
export const ArtImage = styled.article`
  margin: 3rem 0;
  position: relative;

  @media screen and (min-width: 768px) {
    margin: 0;
  }

  img {
    width: 100%;
    display: block;
    box-shadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);

    @media screen and (min-width: 768px) {
      max-height: 500px;
    }
  }
`
export const ImgContainer = styled.div`
  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    filter: blur(30px);
    background: linear-gradient(200deg, #510f66, #9e587d, #00e0ff);
  }
  @media screen and (min-width: 768px) {
    max-height: 500px;
  }
  @media screen and (min-width: 992px) {
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border: 3px solid white;
      box-sizing: border-box;
      top: -16px;
      left: -16px;
      z-index: -1;
    }
  }
`

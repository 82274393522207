import React from "react"
import Button from "../Button"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import styled from "styled-components"
const Contact = () => {
  return (
    <ContactContainer>
      <Question>Do you want to see more? Lets talk</Question>
      <AniLink fade to="/contact/">
        <Button>Contact</Button>
      </AniLink>
    </ContactContainer>
  )
}

export default Contact
const Question = styled.h1`
  font-size: 2.3rem;
`
const ContactContainer = styled.section`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80vw;
  height: 200px;
  margin: 1rem auto;
  flex-direction: column;

  @media screen and (min-width: 1200px) {
    width: 95vw;
    max-width: 1170px;
  }
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`

import React from "react"
import Button from "../Button"
import Anilink from "gatsby-plugin-transition-link"
import styled from "styled-components"
import TextContainer from "../TextContainer"
import Title from "../Title"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const getImage = graphql`
  query skillImage {
    skillImage: file(relativePath: { eq: "ComponentImages/skills.jpg" }) {
      childImageSharp {
        fluid(quality: 70, maxWidth: 560) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
const Skills = () => {
  const { skillImage } = useStaticQuery(getImage)

  return (
    <Container className="card">
      <Title title="my Skills" subtitle="" />
      <AboutCenter>
        <TextContainer className="card-body">
          <ul>
            <li>React</li>
            <li>Javascript</li>
            <li>Gatsby</li>
            <li>GraphQl</li>
            <li>HTML</li>
            <li>CSS</li>
            <li>Python</li>
            <li>I.A</li>
            <li>Generative Art</li>
            <li>SVG</li>
          </ul>
          <p>
            Lets see where I apply my skills... Click here to see my projects:
          </p>
          <Anilink fade to="/projects/">
            <Button>Projects</Button>
          </Anilink>
        </TextContainer>
        <SkillImage>
          <ImgContainer className="cardImage">
            <Img fluid={skillImage.childImageSharp.fluid} alt="Skills" />
          </ImgContainer>
        </SkillImage>
      </AboutCenter>
    </Container>
  )
}

export default Skills
export const Container = styled.section`
  padding: 4rem 0;
`
export const AboutCenter = styled.div`
  width: 80vw;
  margin: 0 auto;
  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 3rem;
    align-items: center;
    margin-top: 3rem;
  }
  @media screen and (min-width: 1200px) {
    width: 95vw;
    max-width: 1170px;
  }
`
export const SkillImage = styled.article`
  margin: 3rem 0;
  position: relative;
  @media screen and (min-width: 768px) {
    margin: 0;
  }
  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    filter: blur(30px);
    background: linear-gradient(200deg, #510f66, #9e587d, #00e0ff);
  }
  img {
    width: 100%;
    display: block;
    box-shadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
    @media screen and (min-width: 768px) {
      max-height: 500px;
    }
  }
`
export const ImgContainer = styled.div`
  box-shadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  @media screen and (min-width: 768px) {
    max-height: 500px;
  }
  @media screen and (min-width: 992px) {
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border: 3px solid white;
      box-sizing: border-box;
      top: -16px;
      left: -16px;
      z-index: -1;
    }
  }
`

import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero/Hero"
import About from "../components/Home/About"
import Skills from "../components/Home/Skills"
import Contact from "../components/Home/Contact"
import Art from "../components/Home/Art"
import Title from "../components/Home/Title"
const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Front End Developer" />
    <Hero
      className="indexHero"
      img={data.indexBanner.childImageSharp.fluid}
      home="True"
    />
    <Title />
    <About />
    <Skills />
    <Art />
    <Contact />
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    indexBanner: file(relativePath: { eq: "banner1.jpg" }) {
      childImageSharp {
        fluid(quality: 60, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

import React from "react"
import styled from "styled-components"
const Button = ({ children }) => {
  return (
    <StyledButton type="button" className="buttonPrimary">
      {children}
    </StyledButton>
  )
}

export default Button
const StyledButton = styled.button`
  position: relative;
  border: 2px solid white;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  padding: 5px 0;
  display: inline-block;
  cursor: pointer;
  background: none;
  text-decoration: none;
  display: inline-block;
  transition: all 0.4s ease 0s;
  width: 100px;
  height: 50px;
  box-sizing: border-box;

  &:before {
    content: " ";
    border: 2px solid rgba(0, 0, 0, 0);
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    transition: all 0.4s;
  }
  &:hover {
    color: #00b1d2;

    &:before {
      border: 2px solid white;
      width: calc(100% - 10px);
      height: calc(100% + 10px);
    }
    a {
      color: #00b1d2;
    }

    transition: all 0.4s ease 0s;
  }
  a {
    text-decoration: none;
    color: white;
  }
`

import React from "react"
import styled from "styled-components"
import Button from "../Button"
import Anilink from "gatsby-plugin-transition-link"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import TextContainer from "../TextContainer"
import Title from "../Title"

const getImage = graphql`
  query aboutImage {
    aboutImage: file(relativePath: { eq: "ComponentImages/cybot.jpg" }) {
      childImageSharp {
        fluid(quality: 70, maxWidth: 560) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

const About = () => {
  const { aboutImage } = useStaticQuery(getImage)

  return (
    <Container className="card">
      <Title title="The person" subtitle="" />
      <AboutCenter>
        <TextContainer className="card-body">
          <h1>
            "Logic will get you from A to B. Imagination will take you
            everywhere."
          </h1>
          <p>
            I'm a problem solver, always analyzing what can work, what can be
            improved, and asking questions that help me see the world from others'
            perspectives. I believe in asking the right questions and that
            cooperation is the key component of growth. I'm a man whose values
            drive everything I do, and who puts a piece of himself in everything
            I touch.
          </p>

          <Anilink fade to="/about/">
            <Button>About</Button>
          </Anilink>
        </TextContainer>
        <AboutImage>
          <ImgContainer className="cardImage">
            <Img
              fluid={aboutImage.childImageSharp.fluid}
              alt="Cybot Real Robot"
            />
          </ImgContainer>
        </AboutImage>
      </AboutCenter>
    </Container>
  )
}

export default About

export const Container = styled.section`
  padding: 4rem 0;
`
export const AboutCenter = styled.div`
  width: 80vw;
  margin: 0 auto;
  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 3rem;
    align-items: center;
    margin-top: 3rem;
  }
  @media screen and (min-width: 1200px) {
    width: 95vw;
    max-width: 1170px;
  }
`
export const AboutImage = styled.article`
  margin: 3rem 0;
  position: relative;

  @media screen and (min-width: 768px) {
    margin: 0;
  }

  img {
    width: 100%;
    display: block;
    box-shadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);

    @media screen and (min-width: 768px) {
      max-height: 500px;
    }
  }
`
export const ImgContainer = styled.div`
  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    filter: blur(30px);
    background: linear-gradient(200deg, #510f66, #9e587d, #00e0ff);
  }
  @media screen and (min-width: 768px) {
    max-height: 500px;
  }
  @media screen and (min-width: 992px) {
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border: 3px solid white;
      box-sizing: border-box;
      top: -16px;
      left: -16px;
      z-index: -1;
    }
  }
`

import React from "react"
import styled from "styled-components"
const TextContainer = ({ children }) => {
  return <Container className="column">{children}</Container>
}
const Container = styled.div`
  margin-top: 2rem;
  button {
    margin: 0 auto;
    display: block;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1.25rem;
  }
  p {
    font-family: "Montserrat", sans-serif;
    margin-bottom: 1.25rem;
  }
  @media screen and (min-width: 768px) {
    p {
      width: 80%;
    }
  }
  ul {
    list-style: none;
    columns: 3;
    margin-bottom: 1.25rem;
  }
`
export default TextContainer
